var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "upload-box" }, [
    _c("ul", { staticClass: "video-box" }, [
      !_vm.videoUrl
        ? _c("li", { staticClass: "mvGo" }, [
            !_vm.loading
              ? _c("i", {
                  staticClass: "iconfont el-icon-video-camera",
                  staticStyle: {
                    color: "#D3D3D3",
                    "font-size": "50px",
                    position: "relative",
                    top: "10px",
                  },
                })
              : _c("span", [
                  _c("i", { staticClass: "el-icon-loading" }),
                  _vm._v(" " + _vm._s(_vm.progress.toFixed(2)) + "% "),
                ]),
            _c("input", {
              attrs: { id: "files", type: "file", accept: "video/*" },
              on: {
                change: function ($event) {
                  return _vm.changeVideo($event)
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.videoUrl
        ? _c(
            "li",
            {
              staticClass: "haveVd",
              on: {
                mouseleave: function ($event) {
                  _vm.showDel = false
                },
                mouseenter: function ($event) {
                  _vm.showDel = true
                },
              },
            },
            [
              _vm.videoUrl
                ? _c("img", {
                    attrs: {
                      src:
                        _vm.videoUrl +
                        "?x-oss-process=video/snapshot,t_1000,m_fast",
                    },
                    on: { click: _vm.showVideo },
                  })
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDel,
                          expression: "showDel",
                        },
                      ],
                      on: { click: _vm.deleteVideo },
                    },
                    [_vm._v(" 删除 ")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]),
    _c("video", {
      staticStyle: { display: "none" },
      attrs: { src: _vm.videoUrl },
      on: { canplaythrough: _vm.canplaythrough },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }