<template>
  <div class="upload-box">
    <ul class="video-box">
      <li
        v-if="!videoUrl"
        class="mvGo"
      >
        <i
          v-if="!loading"
          class="iconfont el-icon-video-camera"
          style="color: #D3D3D3; font-size: 50px; position: relative;top: 10px;"
        />
        <span v-else>
          <i class="el-icon-loading" />
          {{ progress.toFixed(2) }}%
        </span>
        <input
          id="files"
          type="file"
          accept="video/*"
          @change="changeVideo($event)"
        >
      </li>
      <li
        v-if="videoUrl"
        class="haveVd"
        @mouseleave="showDel = false"
        @mouseenter="showDel = true"
      >
        <img
          v-if="videoUrl"
          :src="videoUrl + '?x-oss-process=video/snapshot,t_1000,m_fast'"
          @click="showVideo"
        >
        <button
          v-if="!disabled"
          v-show="showDel"
          @click="deleteVideo"
        >
          删除
        </button>
      </li>
    </ul>
    <video style="display: none" :src="videoUrl" @canplaythrough="canplaythrough"></video>
  </div>
</template>

<script>
import { upload } from '@/utils/oss'
export default {
  name: 'UploadVideoOss',
  components: {
  },
  props: {
    type: {
      type: String,
      default: 'avatar'
    },
    url: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // dragOptions: {
      //   group: 'name'
      // },
      videoTime: 0,
      loading: false,
      isProgress: false,
      percentage: 0,
      imageUrlList: [],
      imageUrl: '',
      showBigImgIndex: 0,
      videoUrl: '', // 视频url
      showDel: false,
      progress: 0
    }
  },
  watch: {
    url() {
      this.videoUrl = this.url
    }
  },
  created() {
    this.videoUrl = this.url;
  },
  methods: {
    // 上传改变
    changeVideo(e) {
      // if (e.target.files[0].size > 1024 * 1024 * 300) {
      //   this.$message('视频不能大于300M')
      //   return
      // }
      this.multipartUpload(e.target.files[0])
    },
    // 定义上传方法
    async multipartUpload(file) {
      try {
        this.loading = true;
        upload(file).then((res)=>{
          const url = res.url.split('?')[0];
          this.videoUrl = url;
          this.$emit('success', url);
          this.progress = 100
        }).finally(()=>{
          this.loading = false;
        })
      } catch (e) {
        console.log(e)
      }
    },
    deleteVideo() {
      this.videoUrl = ''
      this.$emit('success', '')
    },
    showVideo() {
      window.open(this.videoUrl, '_blank')
    },
    canplaythrough(e) {
      this.videoTime = e.target.duration;
    }
  }
}
</script>

<style lang="scss" scoped>
.mvGo {
  width: 100px;
  height: 100px;
  float:left;
  border: 1px dashed #C0CCDA;
  position: relative;
  text-align: center;
  line-height: 100px;
  border-radius: 4px;
  list-style-type:none;

}
.mvGo input[type=file] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.haveVd {
  width: 100px;
  height: 100px;
  display: inline-block;
  border: 1px solid #C0CCDA;
  position: relative;
  text-align: center;
  line-height: 100px;
  border-radius: 4px;
  background: #898989;
  overflow: hidden;
  list-style-type: none;

}
.haveVd span {
  color: #fff;
}
.haveVd button {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #BFCBD9;
  cursor: pointer;
  height: 20px;
  width: 100%;
  color: #fff;
  z-index: 10;
}
.haveVd img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btns {
  padding: 0 120px;
  margin-top: 30px;
}
.video-box{
  overflow: hidden;
  padding: 0;
  position: relative;

}
.talk-imgs-box img{
  margin-right: 20px;
  width: 100px;
  height: 100px;
}
.img-wrap{
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  position: relative;
}
.img-delete{
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
